<template>
  <div class="breadcrumb">
    <div v-for="(item,indexPage) in pages" :key="indexPage" class="breadcrumb-item">
      <p 
        :class="indexPage !=(pages.length-1)?'text-label-item':'actual-page'"
        @click="$router.push(item.to)"
      >
        {{item.label}}
      </p>      
      <i v-if="(indexPage+1) < pages.length">/</i>
    </div>
    
  </div>
</template>

<script>
export default { 
  props:{
    pages:{
      type:Array,
      required:true, 
      default:[]   
    }
  },
  data() {
    return {     
    };
  },
};
</script>

<style lang="scss">
.breadcrumb {
  display: flex;
  background-color: transparent;
  border: none;
  padding-top: 5px;
  position: relative;
  right: 5px;
}

.breadcrumb-item{
  display: flex;
}
.text-label-item{
  margin-inline: 10px;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  font-family: "Montserrat", Helvetica, Arial, sans-serif;

}

.actual-page{
  margin-inline: 10px;
  color:  rgba(var(--vs-primary), 1);
  cursor: pointer;
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
}
</style>