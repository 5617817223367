var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "breadcrumb" },
    _vm._l(_vm.pages, function (item, indexPage) {
      return _c("div", { key: indexPage, staticClass: "breadcrumb-item" }, [
        _c(
          "p",
          {
            class:
              indexPage != _vm.pages.length - 1
                ? "text-label-item"
                : "actual-page",
            on: {
              click: function ($event) {
                return _vm.$router.push(item.to)
              },
            },
          },
          [_vm._v("\n      " + _vm._s(item.label) + "\n    ")]
        ),
        indexPage + 1 < _vm.pages.length ? _c("i", [_vm._v("/")]) : _vm._e(),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }